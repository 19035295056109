.App {
  text-align: center;
}

.App-header {
  height: 80px;
}

.App-body {
  height: calc(100vh - 110px);
  /* height: calc(100vh - 80px); */
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 10px rgb(0, 0, 0),
    -2px 2px 10px rgb(0, 0, 0),
    2px -2px 10px rgb(0, 0, 0),
    -2px -2px 10px rgb(0, 0, 0);

  /* align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center; */
}

/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

.App-Fallback {
  width: inherit;
  height: inherit;
  text-align: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}