body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./images/background.webp");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  font-family: 'M PLUS Rounded 1c';
  src: local('M PLUS Rounded 1c'), url(./fonts/MPLUSRounded1c-Regular.ttf) format('ttf');
}
